import {API, Auth} from "aws-amplify";

const CONTENT_FORM_ID = "content";
const IDENTIFIER_FORM_ID = "identifier";
const CONTENT_SIZE_LIMIT = 5000000;

const LIMIT_ERROR = "Content must contain less than 5 million characters.";

export const getConceptsFromContent = async (content, formId) => {
    if (content.length > CONTENT_SIZE_LIMIT) {
        return {"error": LIMIT_ERROR};
    }
    let body;
    if (formId === CONTENT_FORM_ID) {
        body = {content: content};
    } else if (formId === IDENTIFIER_FORM_ID) {
        body = {identifier: content};
    } else {
        body = {url: content};
    }
    const request = {
        headers: {
            "Content-Type": "application/json",
            Authorization: (await Auth.currentSession()).idToken.jwtToken
        },
        body
    };
    try {
        const response = await API.post("eurovoc", "/classification", request);
        return response.concepts;
    } catch (e) {
        console.error(e);
        return undefined;
    }
};
