import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import RequestForm from "./RequestForm";
import SignOutButton from "./SignOutButton";
import About from "./About";
import "./Tabs.css";

const CONTENT_FORM_ID = "content";
const IDENTIFIER_FORM_ID = "identifier";
const URL_FORM_ID = "url";
const ABOUT_ID = "about";

class Tabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: CONTENT_FORM_ID
        };

        this.onTabChanged = this.onTabChanged.bind(this);
        this.getTabClass = this.getTabClass.bind(this);
    }

    onTabChanged(eventKey) {
        this.setState({ selectedTab: eventKey });
    }

    getTabClass(tabId) {
        return this.selectedTab === tabId ? "active_tab" : "inactive_tab";
    }

    render() {
        const { selectedTab } = this.state;

        return (
            <React.Fragment>
                <Navbar bg="dark" variant="dark" expand="sm">
                    <Navbar.Brand>EuroVoc Classifier</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav defaultActiveKey={CONTENT_FORM_ID} onSelect={this.onTabChanged} className="mr-auto">
                            <Nav.Item>
                                <Nav.Link eventKey={CONTENT_FORM_ID}>Content</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={IDENTIFIER_FORM_ID}>Identifier</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={URL_FORM_ID}>URL</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="about_link">
                                <Nav.Link eventKey={ABOUT_ID}>About</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Nav defaultActiveKey={CONTENT_FORM_ID} onSelect={this.onTabChanged}>
                            <Nav.Item>
                                <SignOutButton />
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {selectedTab !== ABOUT_ID && <RequestForm formId={selectedTab} onSubmitCallback={this.onSubmit} />}
                {selectedTab === ABOUT_ID && <About />}
            </React.Fragment>
        );
    }
}

export default Tabs;
