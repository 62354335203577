import React, { Component } from "react";
import conceptsCsvPath from "../concepts.csv";
import ConcepstList from "./ConceptsList";
import "./About.css";

class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            concepts: []
        };

        this.readConceptsCsv = this.readConceptsCsv.bind(this);
        this.readFile = this.readFile.bind(this);
    }

    componentDidMount() {
        this.readConceptsCsv().then(concepts => {
            this.setState({ concepts });
        });
    }

    async readConceptsCsv() {
        const response = await fetch(conceptsCsvPath);
        const csvContent = await this.readFile(response);
        const csvLines = csvContent.split("\n");
        const concepts = [];
        for (const key in csvLines) {
            const splitLine = csvLines[key].split(",");
            if (splitLine.length >= 2) {
                concepts.push({ identifier: splitLine[0], label: splitLine[1] });
            }
        }
        return concepts;
    }

    async readFile(fetchResponse) {
        const reader = fetchResponse.body.getReader();
        const decoder = new TextDecoder("utf-8");
        const result = await reader.read();
        return decoder.decode(result.value);
    }

    render() {
        return (
            <div className="about_body">
                <p>This application is powered by ReactJS and a serverless architecture on AWS.</p>
                <p>It has been trained to handle the 100 most common Eurovoc concepts:</p>
                <ConcepstList concepts={this.state.concepts} />
            </div>
        );
    }
}

export default About;
