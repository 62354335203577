import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ConceptsList from "./ConceptsList";
import "./ConceptsModal.css";

const LONG_LOADING_SECONDS = 3;

class ConceptsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: props.show,
            loadingMessage: "",
            timeoutHandler: null
        };

        this.onClose = this.onClose.bind(this);
        this.onLongLoading = this.onLongLoading.bind(this);
        this.state.timeoutHandler = setTimeout(this.onLongLoading, LONG_LOADING_SECONDS * 1000);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show !== this.state.show && nextProps.show) {
            clearTimeout(this.state.timeoutHandler);
            this.setState({
                timeoutHandler: setTimeout(this.onLongLoading, LONG_LOADING_SECONDS * 1000)
            });
        }
        this.setState({ show: nextProps.show });
    }

    onClose() {
        this.props.onCloseCallback();
        clearTimeout(this.state.timeoutHandler);
        this.setState({ loadingMessage: "" });
    }

    onLongLoading() {
        this.setState({
            loadingMessage: "Loading might take some time due to cold start of lambdas."
        });
    }

    render() {
        const { show, concepts, isLoading } = this.props;

        return (
            <Modal show={show} onHide={this.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Detected EuroVoc concepts</Modal.Title>
                </Modal.Header>

                <Modal.Body className="results_modal_body">
                    {isLoading ? (
                        <div className="loading">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p>{this.state.loadingMessage}</p>
                        </div>
                    ) : (
                        <ConceptsList concepts={concepts} />
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.onClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ConceptsModal;
