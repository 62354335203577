import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import "./Concept.css";

const urlPrefix = "https://publications.europa.eu/en/web/eu-vocabularies/th-concept/-/resource/eurovoc/";

class Concept extends Component {
    render() {
        const { identifier, label } = this.props;

        return (
            <Button variant="success" href={urlPrefix + identifier} target="_blank" className="concept_button">
                {label}
            </Button>
        );
    }
}

export default Concept;
