import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
import Concept from "./Concept";
import "./ConceptsList.css";

class ConceptsList extends Component {
    render() {
        const {concepts} = this.props;

        return (
            <div className="concepts_list">
                {concepts === undefined ? (
                    <Alert variant="danger">An error occurred during the request.</Alert>
                ) : concepts.error !== undefined ? (
                    <Alert variant="danger">{concepts.error}</Alert>
                ) : concepts.length === 0 ? (
                    <p>No concept found</p>
                ) : (
                    concepts.map(concept => {
                        return (
                            <Concept key={concept.identifier} identifier={concept.identifier} label={concept.label}/>
                        );
                    })
                )}
            </div>
        );
    }
}

export default ConceptsList;
