import React, { Component } from "react";
import "./PoweredBy.css";

class About extends Component {
    render() {
        return (
            <div className="content">
                Powered by
                <img
                    className="arhs-logo"
                    src={require("../arhs-spikeseed-logo.png")}
                    alt="ARHS Spikeseed logo"
                />
            </div>
        );
    }
}

export default About;
