import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import { Auth } from "aws-amplify";

class Concept extends Component {
    onSignOut() {
        const currentUser = Auth.userPool.getCurrentUser();
        currentUser.signOut();
        window.location.reload();
    }

    render() {
        return (
            <Nav.Link onClick={this.onSignOut}>Logout</Nav.Link>
        );
    }
}

export default Concept;
