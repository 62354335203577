import Amplify from "aws-amplify";
import cognitoConfig from "./cognito_config.json";

Amplify.configure({
    Auth: cognitoConfig,
    API: {
        endpoints: [
            {
                name: "eurovoc",
                endpoint: cognitoConfig["endpoint"]
            }
        ]
    }
});
