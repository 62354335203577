import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ConceptsModal from "./ConceptsModal";
import {getConceptsFromContent} from "../api";
import "./RequestForm.css";

const CONTENT_FORM_ID = "content";
const IDENTIFIER_FORM_ID = "identifier";

class RequestForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            field: "",
            concepts: undefined,
            isLoading: false,
            showModal: false
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    }

    onChange(e) {
        this.setState({field: e.target.value});
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({
            showModal: true,
            isLoading: true
        });
        const concepts = await getConceptsFromContent(this.state.field, this.props.formId);
        this.setState({
            isLoading: false,
            concepts
        });
    }

    onModalClose() {
        this.setState({showModal: false});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.formId !== this.props.formId) {
            this.setState({field: ""});
        }
    }

    render() {
        const {formId} = this.props;
        const {field, concepts, isLoading, showModal} = this.state;

        return (
            <React.Fragment>
                <Form className="form_body">
                    <Form.Group controlId="field">
                        {formId === CONTENT_FORM_ID ? (
                            <React.Fragment>
                                <Form.Label className="form_label">Content of the document</Form.Label>
                                <div className="form_description">
                                    <p>You can enter the content of a CELLAR document in the following box.</p>
                                    <p className="for_example_text">For example:</p>
                                    <p className="example">
                                        16.9.2009 EN Official Journal of the European Union L 244/1 COMMISSION
                                        REGULATION (EC) No 837/2009 of 15 September 2009 establishing the standard
                                        import values for determining the entry price of certain fruit and vegetables
                                        THE COMMISSION OF THE EUROPEAN COMMUNITIES Having regard to the Treaty
                                        establishing the European Community Having regard to Council Regulation (EC) No
                                        1234/2007 of 22 October 2007 establishing a common organisation of agricultural
                                        markets and on specific provisions for certain agricultural products (Single CMO
                                        Regulation) (1) Having regard to Commission Regulation (EC) No 1580/2007 of 21
                                        December 2007 laying down implementing rules for Council Regulations (EC) No
                                        2200/96 (EC) No 2201/96 and (EC) No 1182/2007 in the fruit and vegetable sector
                                        (2) and in particular Article 138(1) thereof Whereas: Regulation (EC) No
                                        1580/2007 lays down pursuant to the outcome of the Uruguay Round multilateral
                                        trade negotiations the criteria whereby the Commission fixes the standard values
                                        for imports from third countries in respect of the products and periods
                                        stipulated in Annex XV Part A thereto HAS ADOPTED THIS REGULATION: Article 1 The
                                        standard import values referred to in Article 138 of Regulation (EC) No
                                        1580/2007 are fixed in the Annex hereto. Article 2 This Regulation shall enter
                                        into force on 16 September 2009. This Regulation shall be binding in its
                                        entirety and directly applicable in all Member States. Done at Brussels 15
                                        September 2009. For the Commission Jean-Luc DEMARTY Director-General for
                                        Agriculture and Rural Development (1) OJ L 299 16.11.2007 p. 1. (2) OJ L 350
                                        31.12.2007 p. 1. ANNEX Standard import values for determining the entry price of
                                        certain fruit and vegetables (EUR/100 kg) CN code Third country code (1)
                                        Standard import value 0702 00 00 MK 32 7 ZZ 32 7 0707 00 05 MK 27 4 TR 103 3 ZZ
                                        65 4 0709 90 70 TR 101 0 ZZ 101 0 0805 50 10 AR 79 9 CL 134 9 UY 81 3 ZA 84 9 ZZ
                                        95 3 0806 10 10 EG 137 1 IL 227 0 TR 90 9 ZZ 151 7 0808 10 80 AR 118 6 BR 68 1
                                        CL 84 5 NZ 85 2 US 85 9 ZA 77 0 ZZ 86 6 0808 20 50 AR 160 8 CN 70 8 TR 99 4 ZA
                                        69 4 ZZ 100 1 0809 30 TR 118 3 US 228 1 ZZ 173 2 0809 40 05 IL 126 2 TR 113 9 ZZ
                                        120 1 (1) Nomenclature of countries laid down by Commission Regulation (EC) No
                                        1833/2006 (OJ L 354 14.12.2006 p. 19). Code ‘ZZ’ stands for ‘of other origin’.
                                    </p>
                                </div>
                                <Form.Control as="textarea" className="content_area" onChange={this.onChange}/>
                            </React.Fragment>
                        ) : formId === IDENTIFIER_FORM_ID ? (
                            <React.Fragment>
                                <Form.Label className="form_label">Identifier of the document</Form.Label>
                                <div className="form_description">
                                    <p>
                                        You can enter the CELLAR identifier corresponding of a document in the following
                                        box.
                                        <br/>
                                        The identifier is transformed into an URL of form
                                        <i> https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=celex:{"{identifier}"}</i>,
                                        and the body of this page is retrieved as text to find associated
                                        concepts.
                                        <br/>
                                        It means that the document must have an HTML version available.
                                    </p>
                                    <p className="for_example_text">For example:</p>

                                    <ul className="example">
                                        <li>celex:91996E001102</li>
                                        <li>celex:32005R2111</li>
                                        <li>oj:JOL_2013_330_R_0001_01</li>
                                        <li>celex:52007SC0181</li>
                                    </ul>
                                </div>
                                <Form.Control type="text" onChange={this.onChange}/>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Form.Label className="form_label">URL of the document</Form.Label>
                                <div className="form_description">
                                    <p>
                                        You can enter the URL to the document to classify in the following box.
                                        <br/>
                                        The document must be in HTML format.
                                    </p>
                                    <p className="for_example_text">For example:</p>
                                    <ul className="example">
                                        <li>
                                            https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=celex:91996E001102
                                        </li>
                                        <li>
                                            https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=celex:32005R2111
                                        </li>
                                        <li>
                                            https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=oj:JOL_2013_330_R_0001_01
                                        </li>
                                        <li>
                                            https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=celex:52007SC0181
                                        </li>
                                    </ul>
                                </div>
                                <Form.Control type="text" onChange={this.onChange}/>
                            </React.Fragment>
                        )}
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={this.onSubmit} disabled={field === ""}>
                        Categorize
                    </Button>
                </Form>
                <ConceptsModal
                    onCloseCallback={this.onModalClose}
                    concepts={concepts}
                    isLoading={isLoading}
                    show={showModal}
                />
            </React.Fragment>
        );
    }
}

export default RequestForm;
