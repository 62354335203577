import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import "./CustomConfirmSignUp.css";

class CustomConfirmSignUp extends Component {
    constructor(props) {
        super(props);

        this.goBackToHome = this.goBackToHome.bind(this);
    }

    goBackToHome() {
        this.props.onStateChange("signIn", {});
    }

    render() {
        const { authState } = this.props;

        if (authState === "confirmSignUp") {
            return (
                <div className="confirm_signup_body">
                    <h1 className="confirm_signup_title"> You have been successfully signed up. </h1>
                    <p className="confirm_signup_details"> Now an administrator have to validate your account. </p>
                    <Button onClick={this.goBackToHome}>Go to home page</Button>
                </div>
            );
        } else {
            return <React.Fragment />;
        }
    }
}

export default CustomConfirmSignUp;
