import React, { Component } from "react";
import { withAuthenticator } from "aws-amplify-react";
import Tabs from "./components/Tabs";
import PoweredBy from "./components/PoweredBy";
import CustomConfirmSignUp from "./CustomConfirmSignUp";
import {
    SignUp,
    SignIn,
    VerifyContact,
    ForgotPassword
} from "aws-amplify-react";

const signUpConfig = {
    hideAllDefaults: true,
    signUpFields: [
        {
            label: "Email",
            key: "username",
            required: true,
            placeholder: "Enter your email",
            type: "email",
            displayOrder: 1
        },
        {
            label: "Confirm email",
            key: "email",
            required: true,
            placeholder: "Enter your email",
            type: "email",
            displayOrder: 2
        },
        {
            label: "Password",
            key: "password",
            required: true,
            placeholder: "Enter your password",
            type: "password",
            displayOrder: 3
        }
    ]
};

const authenticatorComponents = [
    <SignUp signUpConfig={signUpConfig} />,
    <SignIn />,
    <CustomConfirmSignUp />,
    <VerifyContact />,
    <ForgotPassword />
];

const AppWithAuthenticator = withAuthenticator(Tabs, false, authenticatorComponents, null, null, {signUpConfig});

class App extends Component {
    render() {
        return (
            <div>
                <AppWithAuthenticator />
                <PoweredBy/>
            </div>
        );
    }
}

export default App;
